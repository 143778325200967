import "./skills.scss"
import { useRef, useState } from 'react'
import Grid from '@mui/material/Grid';
import Satellite3D from '../../components/satellite3d/Satellite3D';
import SkillsAnimate from '../../components/skillsanimate/SkillsAnimate';
import Rating from '../../components/rating/Rating';

function Skills() {
  const morphCommandRef = useRef(null);

  const morph = (index) => {
    morphCommandRef.current(index);
}

  return (
    <div className="skills" id="skills">
      <Grid
        container
        direction={{ xs: "column-reverse", md: "row" }}
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={5} md={5}>
          <table>
            <tbody>
              <tr onClick={() => morph(3)}>
                <td className="left-cell">RTL Design</td> 
                <td><Rating value={100} /></td>
              </tr>
              <tr onClick={() => morph(4)}>
                <td className="left-cell">PCB Design</td>
                <td><Rating value={70} /></td>
              </tr>
              <tr onClick={() => morph(0)}>
                <td className="left-cell">RF Design</td>
                <td><Rating value={30} /></td>
              </tr>
              <tr onClick={() => morph(6)}>
                <td className="left-cell">Linux Development </td>
                <td><Rating value={50} /></td>
              </tr>
              <tr onClick={() => morph(5)}>
                <td className="left-cell">Signal Processing</td>
                <td><Rating value={70} /></td>
              </tr>
              <tr onClick={() => morph(2)}>
                <td className="left-cell">Programming </td>
                <td><Rating value={50} /></td>
              </tr>
            </tbody>
          </table>
        </Grid>
        <Grid item xs={4} md={6} style={{ height: "70%", width: "98%"}}>
          <div className="canvas-satellite glow">
            <SkillsAnimate morphCommandRef={morphCommandRef}/>
          </div>
        </Grid>

      </Grid>
    </div>
  );
}

export default Skills;

