import React, { Suspense, useRef, useState, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, useGLTF } from '@react-three/drei';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { DirectionalLight, DirectionalLightHelper } from 'three';
import { useHelper, useProgress, Html } from '@react-three/drei';
import { Points, PointMaterial } from '@react-three/drei';
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { GUI } from 'lil-gui';
import * as THREE from "three";
import "./CustomShaderMaterial.jsx"; // Import the custom shader
import "./skillsanimate.scss"

// calculate the maximum number of points
function calculateMaxPoints(models){
  let maxCount = 0;
  for(const points of models)
  {
      if(points.count > maxCount)
          maxCount = points.count
  }

  return maxCount;
}

// Correct number of points in each model
function correctModelSize(models, count){
  let updatedModels = []
  for(const points of models) {
    const originalArray = points.array
    const newArray = new Float32Array(count * 3)

    for(let i = 0; i < count; i++) {
      const i3 = i * 3

      if(i3 < originalArray.length) {
        newArray[i3 + 0] = originalArray[i3 + 0]
        newArray[i3 + 1] = originalArray[i3 + 1]
        newArray[i3 + 2] = originalArray[i3 + 2]
      }
      else {
        const randomIndex = Math.floor(points.count * Math.random()) * 3
        newArray[i3 + 0] = originalArray[randomIndex + 0]
        newArray[i3 + 1] = originalArray[randomIndex + 1]
        newArray[i3 + 2] = originalArray[randomIndex + 2]
      }
    }

    updatedModels.push(new THREE.Float32BufferAttribute(newArray, 3))
  }

  return updatedModels;
}

// Extract model and condition for mesh
function Model({ gui, canvas, modelAIndex, modelBIndex, morphCommandRef }) {
  const ref = useRef();
  const pointsRef = useRef();
  const indexRef = useRef(1);
  const isAnimateRef = useRef(false);

  const { contextSafe } = useGSAP({scope: ref});

  let prevIndex = 0

  const uniforms = {
    uPointSize: new THREE.Uniform(0.08),
    uCanvasHeight: new THREE.Uniform(900),
    uResolution: new THREE.Uniform(new THREE.Vector2(1,1)),
    uProgress: new THREE.Uniform(0),
    uColorA: new THREE.Uniform(new THREE.Color('#FF6700')),
    uColorB: new THREE.Uniform(new THREE.Color('#01A7A8'))
  }

  const morphCommand = (index) => {
    if (!isAnimateRef.current & index !== indexRef.current) {
      pointsRef.current.geometry.attributes.position = updatedModels[indexRef.current];
      pointsRef.current.geometry.attributes.aPositionTarget = updatedModels[index];
      pointsRef.current.geometry.attributes.position.needsUpdate = true;
      pointsRef.current.geometry.attributes.aPositionTarget.needsUpdate = true;
      indexRef.current = index;
      isAnimateRef.current = true;
      gsapProgress();
    }
  };

  const gsapProgress = contextSafe(() => {
    gsap.fromTo(
      uniforms.uProgress,
      { value: 0 },
      { value: 1, duration: 2, ease: 'linear' }
    ).then(() => isAnimateRef.current = false);
  })

  const morph0 = () => {morphCommand(0)};
  const morph1 = () => {morphCommand(1)};
  const morph2 = () => {morphCommand(2)};
  const morph3 = () => {morphCommand(3)};
  const morph4 = () => {morphCommand(4)};

  const handleResize = () => {
    if (canvas.current.offsetHeight) {
      ref.current.uniforms.uCanvasHeight.value = canvas.current.offsetHeight * Math.min(window.devicePixelRatio, 2);
    }
  };


  useEffect(() => {

    if (gui) {
      const modelFolder = gui.current.addFolder('Model');
      modelFolder.add(ref.current.uniforms.uPointSize, 'value', 0, 0.5, 0.01).name("PointSize");
      //modelFolder.add(ref.current.uniforms.uProgress, 'value', 0, 1, 0.01).name("progress").listen();
      modelFolder.addColor(ref.current.uniforms.uColorA, 'value').name("ColorA");
      modelFolder.addColor(ref.current.uniforms.uColorB, 'value').name("ColorB");
      modelFolder.add({ morph0 }, 'morph0').name('sat');
      modelFolder.add({ morph1 }, 'morph1').name('sphere');
      modelFolder.add({ morph2 }, 'morph2').name('computer');
      modelFolder.add({ morph3 }, 'morph3').name('dip16');
      modelFolder.add({ morph4 }, 'morph4').name('arduino');
    }

    // Pass the morphCommand to the parent compontent
    morphCommandRef.current = morphCommand;

  }, [])

  //useEffect(() => {
  //  window.addEventListener('resize', handleResize);
  //  handleResize();
  //
  //  return () => {
  //    window.removeEventListener('resize', handleResize);
  //  };

  //}, [])
  

  //useGSAP(() => {
  //  gsap.fromTo(
  //      ref.current.uniforms.uProgress,
  //      { value: 0 },
  //      { value: 1, duration: 3, ease: 'linear' }
  //  )
  //}, {scope: ref})

  const { scene } = useGLTF('models.glb', true, loader => {
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('../../assets/draco/');
    loader.setDRACOLoader(dracoLoader);
  });

  // Extract points
  const models = scene.children.map((child => child.geometry.attributes.position));

  // Correct model size
  const maxCount = calculateMaxPoints(models);
  const updatedModels = correctModelSize(models, maxCount);

  // Randomized point Size array
  const sizesArray = new Float32Array(maxCount)

  for(let i = 0; i < maxCount; i++)
    sizesArray[i] = Math.random()


  // Create Geometry
  const geometry = new THREE.BufferGeometry();
  geometry.setAttribute('position', updatedModels[indexRef.current])
  geometry.setAttribute('aPositionTarget', updatedModels[indexRef.current])
  geometry.setAttribute('aSize', new THREE.BufferAttribute(sizesArray, 1))



  return (
    <group>
      <points ref={pointsRef} geometry={geometry}>
        <customShaderMaterial ref={ref} uniforms={uniforms}/>
      </points>
    </group>
  );
};

function Light(){
  const dirLight = useRef();
  useHelper(dirLight, DirectionalLightHelper, 1, "red");

  return (
    <>
      <directionalLight position={[3, 3, 6]} color={"#FF5500"} intensity={2.5} ref={dirLight} />
    </>
  );
};

function useLilGUI(containerRef) {
  const gui = useRef()

  useEffect(() => {
    if (containerRef.current) {
      gui.current = new GUI({ container: containerRef.current });
      gui.current.close()
    }

    return () => {
      if(containerRef.current) gui.current.destroy()
    }
  }, [containerRef])

  return gui;
}

function Loader() {
  const { progress } = useProgress(); // Tracks loading progress
  return (
    <Html sprite={true} transform={true} distanceFactor={20} position={[1, 1, 1]}>
      <div style={{ color: "orange", fontSize: "18px" }}>
        Loading: {Math.round(progress)}%...
      </div>
    </Html>
  );
}



function SkillsAnimate(props) {


  const guiContainerRef = useRef();
  const canvasRef = useRef();
  const gui = (import.meta.env.MODE === 'development')? useLilGUI(guiContainerRef) : null;


  return (
    <div className="canvas" style={{ position: "relative", width: "inherit", height: "inherit" }}>
      <div
        ref={guiContainerRef}
        style={{
          position: "absolute",
          zIndex: 1,
          pointerEvents: "auto",
        }}
      />
      <Canvas ref={canvasRef} gl={{ antialias: true }} camera={{ fov: 35,  position: [-15, 5, -20] }}>
        <Suspense fallback={<Loader/>}>
          <Model gui={gui} canvas={canvasRef} modelAIndex={0} modelBIndex={1} morphCommandRef={props.morphCommandRef} />
        </Suspense>
        <OrbitControls autoRotate/>
      </Canvas>
      <div className="footer" style={{ position: 'absolute', bottom: '20px', left: '50%', transform: 'translate(-50%, -50%)' }}>
        Select Skill
      </div>
    </div>
  )
}

export default SkillsAnimate
